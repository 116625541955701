<template>
  <div :class="['wiki-banner']">
    <swiper
      ref="mySwiper"
      :auto-destroy="true"
      :auto-update="true"
      :options="swiperOptions"
      class="swiper-box"
      @slideChange="onSlideChange"
    >
      <template>
        <swiper-slide
          ref="swiperSlide"
          v-for="(item, index) in list"
          :key="item.id"
          class="banner-item"
          :style="{ 'border-color': item.color }"
        >
          <div class="back-wrap">
            <a :href="item.jump_url || 'javascript:;'" target="_self">
              <template v-if="isEntry">
                <template v-if="mimeType(item.thumb) === 'image'">
                  <img :src="item.thumb + imgSize.middle" class="swiper-lazy" />
                </template>
                <template v-if="mimeType(item.thumb) === 'video'">
                  <div class="video-wrap">
                    <LazyVideo :sort="index" :data="item" />
                    <!-- <video autoplay loop muted playsinline class="video">
                      <source :src="item.thumb" type="video/mp4" />
                    </video> -->
                  </div>
                </template>
              </template>
              <template v-else>
                <img :src="item.url" class="swiper-lazy" />
              </template>
              <div class="title" :style="{ background: `linear-gradient(to top, ${item.color} 25%, transparent)` }">
                <div class="sup">{{ item.desc }}</div>
                <div class="sub">{{ item.title }}</div>
              </div>
            </a>
          </div>
        </swiper-slide>
      </template>
    </swiper>
    <div :class="['swiper-pagination', 'swiper-pagination-' + index]"></div>
  </div>
</template>

<script>
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";
import { debounce } from "@/plugins/util";
import LazyVideo from "./lazyVideo.vue";

export default {
  name: "bannerContainer",
  components: { LazyVideo, Swiper, SwiperSlide },
  mixins: [],
  props: {
    index: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => []
    },
    isEntry: {
      type: Boolean,
      default: false
    }
  },

  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        },
        spaceBetween: 8,
        pagination: {
          el: ".swiper-pagination-" + this.index
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  activated() {
    const swiper = this.$refs.mySwiper;
    if (swiper) {
      swiper.$swiper.autoplay.start();
    }
  },
  methods: {
    handleJumpUrl(item) {
      if (item.jump_url) {
        window.open(item.jump_url);
      }
    },
    onSlideChange: debounce(function() {
      const nodeList = [
        ...this.$refs.mySwiper.swiperInstance.el.querySelectorAll(
          `[data-swiper-slide-index='${this.$refs.mySwiper.swiperInstance.realIndex}']`
        )
      ];
      const allNodeList = [...this.$refs.mySwiper.swiperInstance.el.querySelectorAll("div.swiper-slide")];
      allNodeList.forEach(node => {
        const videoEl = node.querySelector("video");
        if (!videoEl) return;
        if (nodeList.includes(node)) {
          videoEl.play();
        } else {
          videoEl.pause();
        }
      });
    }, 30),
    /**
     *
     * @param {string} url
     */
    mimeType(url) {
      if (url.endsWith(".png") || url.endsWith(".jpg")) return "image";
      if (url.endsWith(".mp4")) return "video";
      throw new Error("不支持的banner文件类型");
    }
  }
};
</script>

<style lang="less" scoped>
.wiki-banner {
  position: relative;
  width: 416px;
  height: 416px;
  background: var(--zs-wiki-banner-bg);
  border-radius: 18px;
  padding: 6px 7px 8px;
  overflow: hidden;
  .swiper-box {
    height: 100%;
    border-radius: 12px;
  }

  .banner-item {
    height: 100%;
    // border: 3px solid;
    // border-radius: 12px;
    box-sizing: border-box;
    overflow: hidden;
    .back-wrap {
      position: relative;
      border-radius: 10px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        // background: #fff;
        // box-shadow: 0 0 3px #999;
      }
      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        height: 160px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 18px;
        padding-bottom: 17px;
        border-radius: 8px;
        transform: translateZ(999px);
        .sup {
          font-size: 14px;
          color: #e0e0e0;
          padding-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .sub {
          font-size: 40px;
          line-height: 51px;
          color: #fff;
          padding-right: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .video-wrap {
        height: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        .video {
          height: 100%;
          border-radius: 8px;
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.swiper-pagination {
  text-align: right;
  position: absolute;
  right: 24px;
  bottom: 27px;

  /deep/ .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: #424242;
    border-radius: 50%;
    opacity: 1;
    margin: 0 3px;
  }

  /deep/ .swiper-pagination-bullet-active {
    background: #a4e4ff;
  }
}
</style>

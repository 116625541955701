<template>
  <div class="lazy-video">
    <img v-show="!showVideo && poster" class="poster" :src="poster" />
    <video v-show="showVideo" ref="video" loop muted playsinline preload="none">
      <source :src="data.thumb" type="video/mp4" />
    </video>
  </div>
</template>
<script>
export default {
  name: "LazyVideo",
  props: {
    data: { type: Object, required: true },
    sort: { type: Number, required: true }
  },
  data() {
    return {
      showVideo: false,
      poster: ""
    };
  },
  mounted() {
    this.poster = this.data.cover + this.imgSize.middle;
    this.listenVideoLoad();
  },
  methods: {
    listenVideoLoad() {
      /** @type {HTMLVideoElement} */
      const video = this.$refs.video;
      if (!video) {
        setTimeout(this.listenVideoLoad, 30);
        return;
      }
      setTimeout(() => {
        video
          .play()
          .then(() => {
            this.showVideo = true;
          })
          .catch(() => {
            this.showVideo = false;
          });
      }, (this.sort + 1) * 1000);
    }
  }
};
</script>
<style lang="less" scoped>
.lazy-video {
  position: relative;
  height: 100%;
  .poster {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
